import React from "react";
import ReactDOM from "react-dom";
import "./assets/style.scss";
import {App} from "./App";

import {AnimationProvider} from "./contexts/AnimationContext";

import {Provider} from "react-redux";
import store from "./common/Store";

ReactDOM.render(
    <Provider store={store}>
        <AnimationProvider>
            <App/>
        </AnimationProvider>
    </Provider>,
    document.getElementById("root")
);