import React, { createContext, useContext } from "react";
import AnimationHook from "../hooks/AnimationHook";

const AnimationContext = createContext({ });

export const useAnimation = () => {
    return useContext(AnimationContext);
}

export const AnimationProvider = ({ children }) => {
    return (
        <AnimationContext.Provider value={{ ...AnimationHook() }}>
            { children }
        </AnimationContext.Provider>
    )
}
