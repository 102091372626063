import React from "react";
import { Helmet } from "react-helmet";

export const StatusComponent = ({ status }) => {
    return (
        <>
            <Helmet title={ (status === "success" ? "Успешная оплата" : "Ошибка при оплате") + " < DiamondWorld" }/>
            <div className="valign center">
                <div className="notification">
                    { status === "success" ?
                        <>
                            <div>Готово!</div>
                            Валюта уже должна была поступить на ваш счёт, если этого не случилось в течении 5 минут - обратитесь в техническую поддержку или оставьте соответствующую заявку на форуме.<br/>
                            <br/>
                            <a href="https://forum.diamondworld.pro/index.php?forums/donate/" target="_blank" rel="noreferrer">Раздел заявок на форуме</a><br/>
                            <a href="https://help.diamondworld.pro/" target="_blank" rel="noreferrer">Техническая поддержа</a><br/>
                            * Работает с 10:00 до 22:00 по МСК.<br/>
                            <br/>
                            Уведомляем вас, что возврат платежа через платёжную систему приведёт к неминуемой блокировке вашего аккаунта, вне зависимости от состояния платежа.<br/>
                            Проект и его работники не ставят своей целью обмануть вас, если средства не поступили на ваш счёт - обратитесь в тех. поддержку и ваша проблема гарантированно будет решена.
                        </> :
                        <>
                            <div>Произошла ошибка.</div>
                            Во время оплаты что-то пошло не так, повторите попытку снова.<br/>
                            Если данная проблема появится повторно - обратитесь в техническую поддержку.<br/>
                            <br/>
                            <a href="https://forum.diamondworld.pro/index.php?forums/donate/" target="_blank" rel="noreferrer">Раздел заявок на форуме</a><br/>
                            <a href="https://help.diamondworld.pro/" target="_blank" rel="noreferrer">Техническая поддержа</a><br/>
                            * Работает с 10:00 до 22:00 по МСК.<br/>
                        </>
                    }
                </div>
            </div>
        </>
    );
}
