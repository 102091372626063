import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useAnimation } from "../../contexts/AnimationContext";

import { hideNotification } from "../../common/reducer/NotificationReducer";

export default () => {
    // States.
    const message = useSelector((state) => state.notification.message);
    const visible = useSelector((state) => state.notification.visible);

    const { show, hide } = useAnimation();
    const dispatch = useDispatch();

    useEffect(() => {
        if(visible) show(".notification", 0, () => { }, -1, ["scale-in", "scale-out"]);
    }, [visible, show]);

    const closeNotification = () => {
        hide(".notification", 0, () => {
            dispatch(hideNotification());
        }, -1, ["scale-in", "scale-out"]);
    }

    return (
        <>
            {
                !visible ? "" :
                    <aside className="notification hide">
                        <div>
                            <h1>
                                <i className="material-icons">info</i>
                                Уведомление
                            </h1>

                            { message }

                            <button onClick={ closeNotification }>
                                <i className="material-icons">check</i>
                            </button>
                        </div>
                    </aside>
            }
        </>
    );
}
