import { useMemo, useCallback } from "react";

export default () => {
    const queue = useMemo(() => ({ }), []);
    const next = useCallback((id) => {
        const element = (queue[id] || []).shift();

        if(!element) return delete queue[id];

        element();
    }, [queue]);

    const add = useCallback((id, func) => {
        const element = queue[id];
        if(element) return queue[id].push(func);

        queue[id] = [func];
        next(id);
    }, [queue, next]);


    /**
     * Calls the function of changing the value of an element with animation of disappearance and appearance.
     * @param {string} element Element ID (class, id, element name).
     * @param {function} callback A function called after the animation is executed.
     * @param {number} animationTime? Duration of the disappearance/reappearance animation.
     * @param {string[]} animation? An array with the names of animation classes.
     */
    const change = useCallback((element, callback, animationTime = 700, animation = ["fade-in", "fade-out"]) => {
        add(element, () => {
            const domElement = document.querySelector(element);
            if(animationTime < 0)
                animationTime = 700;

            domElement?.classList.remove(`animation-${ animation[0] }`);
            domElement?.classList.add(`animation-${ animation[1] }`);

            setTimeout(() => {
                domElement?.classList.remove(`animation-${ animation[1] }`);
                domElement?.classList.add(`animation-${ animation[0] }`);

                callback();
            }, animationTime - 100);

            setTimeout(() => {
                domElement?.classList.remove(`animation-${animation[0]}`);
                next(element);
            }, animationTime * 2);
        });
    }, [add, next]);

    /**
     * With animation show the element.
     * @param {string} element Element ID (class, id, element name).
     * @param {number} waitTime? Duration of waiting before the appearance call.
     * @param {function} callback? A function called after animation is executed.
     * @param {number} animationTime? Duration of the appearance animation.
     * @param {string[]} animation? An array with the names of animation classes.
     * @param {boolean} hidden? Whether to use the "hidden" class instead of "hide".
     */
    const show = useCallback((element, waitTime = 0, callback = () => { }, animationTime = 700, animation = ["fade-in", "fade-out"], hidden = false) => {
        add(element, () => {
            const domElement = document.querySelector(element);
            if(animationTime < 0)
                animationTime = 700;

            setTimeout(() => {
                domElement?.classList.remove(hidden ? "hidden" : "hide");
                domElement?.classList.add(`animation-${ animation[0] }`);
            }, waitTime);

            setTimeout(() => {
                domElement?.classList.remove(`animation-${ animation[0] }`);
                next(element); callback();
            }, waitTime + animationTime - 100);
        });
    }, [add, next]);

    /**
     * With animation hides the element.
     * @param {string} element Element ID (class, id, element name).
     * @param {number} waitTime? Duration of waiting before the appearance call.
     * @param {function} callback? A function called after animation is executed.
     * @param {number} animationTime? Duration of the appearance animation.
     * @param {string[]} animation? An array with the names of animation classes.
     * @param {boolean} hidden? Whether to use the "hidden" class instead of "hide".
     */
    const hide = useCallback((element, waitTime = 0, callback = () => { }, animationTime = 700, animation = ["fade-in", "fade-out"], hidden = false) => {
        add(element, () => {
            const domElement = document.querySelector(element);
            if(animationTime < 0)
                animationTime = 700;

            setTimeout(() =>
                domElement?.classList.add(`animation-${ animation[1] }`),
            waitTime);

            setTimeout(() => {
                domElement?.classList.add(hidden ? "hidden" : "hide");
                domElement?.classList.remove(`animation-${ animation[1] }`);
                next(element); callback();
            }, waitTime + animationTime - 100);
        });
    }, [add, next]);

    /**
     * Temporarily changes text of the element.
     * @param {string} element Element ID (class, id, element name).
     * @param {string} originalValue Original text of the element that will return after showing "showedValue".
     * @param {string} showedValue Text that will be displayed after the animation.
     * @param {number} waitTime? Duration of the showedValue.
     * @param {number} animationTime? Duration of the disappearance/reappearance animation.
     * @param {string[]} animation? An array with the names of animation classes.
     */
    const blink = useCallback((element, originalValue, showedValue, waitTime = 1500, animationTime = 700, animation = ["fade-in", "fade-out"]) => {
        add(element, () => {
            const domElement = document.querySelector(element);
            if(animationTime < 0)
                animationTime = 700;

            hide(element, 0, () => { domElement.innerText = showedValue }, animationTime, animation, true);
            show(element, 0, () => { }, animationTime, animation, true);

            hide(element, waitTime, () => { domElement.innerText = originalValue }, animationTime, animation, true);
            show(element, 0, () => { }, animationTime, animation, true);
            next(element);
        });
    }, [add, hide, show, next]);

    return { show, hide, change, blink };
}
