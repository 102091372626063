import React, { useState, useEffect, useContext } from "react";

import { useLocation } from "react-router-dom";

import { ParametersContext } from "../../contexts/ParametersContext";

import logo from "../../assets/images/logo.png";
import { LazyLink } from "../common/LazyLink";

export const MenuComponent = () => {
    const [isOpen, setOpen] = useState(false);
    const { isMobile } = useContext(ParametersContext);

    const [components, setComponents] = useState([
        { path: "/", text: "Главная" },
        { path: "http://forum.diamondworld.pro", text: "Форум" },
        { path: "/rules", text: "Правила" },
        { path: "/play", text: "Как играть?" },
        { logo: true },
        { path: "/faq", text: "Поддержка" },
        { path: "/manual", text: "Как пополнить баланс?" },
        { path: "http://vk.com/diamondworld", text: "Мы ВКонтакте" },
    ]);
    const location = useLocation();

    useEffect(() => {
        components.filter(link => link.active)
            .map(link => link.active = false);

        const link = components.find(link => link.path === "/" + location.pathname.split("/")[1]);
        if(link) link.active = true;

        setComponents(components => [...components]);
        // eslint-disable-next-line
    }, [location.pathname]);

    function closeMenu() {
        const menu = document.getElementsByTagName("nav")[0];
        const switcher = document.getElementsByClassName("menu-switcher")[0];

        menu.classList.add("animation-out");
        switcher.classList.add("animation-out");

        setTimeout(() => {
            menu.classList.remove("animation-out");
            switcher.classList.remove("animation-out");

            setOpen(!isOpen);
        }, 500);
    }

    return (
        <section>
            { isMobile ? <></> :
                <nav>{
                    components.slice(0, components.findIndex(link => link.logo))
                        .map(link => <LazyLink
                            link={ link.path }
                            title={ link.text }
                            classes={ link.active ? "active" : "" }
                            key={ Math.random() }
                        />)
                }</nav>
            }

            <img
                className={ `logo ${ isMobile ? "hoverable" : "" }` }
                onClick={ () => setOpen(!isOpen) }
                src={ logo }
                alt="Логотип"
            />

            { isMobile ? <></> :
                <nav>{
                    components.slice(components.findIndex(link => link.logo), components.length)
                        .map(link => <LazyLink
                            link={ link.path }
                            title={ link.text }
                            classes={ link.active ? "active" : "" }
                            key={ Math.random() }
                        />)
                }</nav>
            }

            { !isMobile ? <></> :
                <>
                    <img className="menu-pointer" src="https://img.icons8.com/metro/26/000000/mouse-left-click.png" alt="Иконка клика мыши"/>
                    <i
                        className="material-icons medium animation-in menu-switcher"
                        style={{ display: isOpen ? "block" : "none" }}
                        onClick={ closeMenu }
                    >cancel</i>
                    <nav className="animation-in" style={{ display: isOpen ? "flex" : "none" }}>{
                        components.filter(link => !link.logo)
                            .map(link => <LazyLink
                                link={ link.path }
                                title={ link.text }
                                classes={ link.active ? "active" : "" }
                                key={ Math.random() }
                            />)
                    }</nav>
                </>
            }
        </section>
    );
}
