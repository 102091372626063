import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { DonateComponent } from "./components/pages/DonateComponent";
import { StatusComponent } from "./components/pages/StatusComponent";
import { ManualComponent } from "./components/pages/ManualComponent";

export const useRoutes = () => {
    return (
        <Switch>
            <Route path="/" component={ DonateComponent } exact/>
            <Route path="/success" render={ () => <StatusComponent status="success"/> } exact/>
            <Route path="/failed" render={ () => <StatusComponent status="failed"/> } exact/>
            <Route path="/:category" component={ ManualComponent } exact/>
            <Redirect to="/"/>
        </Switch>
    );
}
