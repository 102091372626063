import React from "react";

import { useNavigation } from "../../hooks/NavigationHook";

export const LazyLink = ({ title = "", link = "/", classes = "" }) => {
    const navigation = useNavigation();

    function click(e) {
        e.preventDefault();

        if(link.startsWith("http"))
            return window.open(link || "diamondworld.pro", '_blank');

        navigation.lazyOpen(link || "/");
    }

    return (
        <a href="!#" className={ classes || "" } onClick={ click }>{ title || "" }</a>
    );
}
