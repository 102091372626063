import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
    name: "notificator",
    initialState: {
        message: "Lorem ipsum",
        visible: false
    },
    reducers: {
        notification: (state, action) => {
            state.message = action.payload.message;
            state.visible = true;
        },
        hideNotification: (state) => {
            state.visible = false;
        }
    },
})

export const { notification, hideNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
