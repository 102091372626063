import React, {useEffect, useState} from "react";
import {BrowserRouter} from "react-router-dom";

import {ParametersContext} from "./contexts/ParametersContext";

import {useRoutes} from "./routes";

import {MenuComponent} from "./components/menu/MenuComponent";
import NotificationComponent from "./components/common/NotificationComponent";

export const App = () => {
    const routes = useRoutes();

    const [isMobile, setMobile] = useState(window.innerWidth <= 1024);
    useEffect(() => {
        window.addEventListener("resize", () => {
            const isMobile = window.innerWidth <= 1024;

            setMobile(isMobile);
        });
    }, []);

    return (
        <BrowserRouter>
            <NotificationComponent/>

            <ParametersContext.Provider value={{isMobile}}>
                <aside className="shadow"/>

                <header className="animation-in">
                    <MenuComponent/>
                </header>

                <main className="animated">
                    {routes}
                </main>
                <footer>
                    <div style={{fontSize: 18}}>© 2017-{new Date().getFullYear()} DIAMONDWORLD.PRO ВСЕ ПРАВА ЗАЩИЩЕНЫ!
                        Сервер DiamondWorld не относится к Mojang Studios.
                    </div>
                    <div style={{fontSize: 14}}>Для получения дополнительной информации и помощи обратитесь по адресу <a
                        href="mailto:admin@diamondworld.pro">admin@diamondworld.pro</a></div>
                    <div style={{fontSize: 14}}>ИП Калашников Виталий Сергеевич (ИНН 261706691771 ОГРНИП
                        324265100143530)
                    </div>
                    <div style={{fontSize: 14}}><a href="https://diamondworld.pro/confidentialityPolicy.pdf/">Политика
                        конфидициальности</a> | <a href="https://diamondworld.pro/userAgreement.pdf/">Пользовательское
                        соглашение</a></div>
                </footer>
            </ParametersContext.Provider>
        </BrowserRouter>
    );
}
